<template>
    <div class="topbar clearfix">
        <div class="topbar-left">
            <router-link to="/">
                <button class="p-link">
                    <!-- <img alt="Logo" src="layout/images/logo_esam.png" class="topbar-logo" /> -->
                    <img alt="Logo" :src="getLogo" class="topbar-logo" />
                </button>
            </router-link>  
        </div>

        <div class="topbar-right">
            <button class="p-link" id="menu-button" @click="onMenuButtonClick">
                <i class="pi pi-angle-left"></i>
            </button>

            <button class="p-link" id="topbar-menu-button" @click="onTopbarMenuButtonClick">
                <i class="pi pi-bars"></i>
            </button>
            <ul :class="topbarItemsClass">
                <Dropdown style="width: 275px" :options="usuario.usuarioCargos" v-model="usuario.sedeId" optionLabel="sede" optionValue="sedeId" placeholder="Seleccionar Sede" @change="usuario.cambiarSede()" />
                <li :class="['profile-item', { 'active-top-menu': activeTopbarItem === 'profile' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'profile' })">
                    <button class="p-link">
                        <img alt="User" class="profile-image" src="layout/images/avatar.png" />
                        <div v-if="usuarioMixin">
                            <span class="topbar-item-name">{{ usuarioMixin.nombreCompleto }}</span>
                            <span class="topbar-item-role"> Online </span>
                        </div>
                    </button>

                    <transition name="layout-submenu-container">
                        <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'profile'">
                            <li role="menuitem">
                                <button class="p-link topbar-item-button">
                                    <i class="pi pi-fw pi-user"></i>
                                    <span>Profile</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button class="p-link topbar-item-button">
                                    <i class="pi pi-fw pi-lock"></i>
                                    <span>Privacy</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button class="p-link topbar-item-button">
                                    <i class="pi pi-fw pi-cog"></i>
                                    <span>Settings</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button class="p-link topbar-item-button">
                                    <i class="pi pi-fw pi-sign-out"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </transition>
                </li>

                <li class="hidden" :class="[{ 'active-top-menu': activeTopbarItem === 'settings' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'settings' })">
                    <button class="p-link">
                        <i class="topbar-icon pi pi-fw pi-cog"></i>
                        <span class="topbar-item-name">Settings</span>
                    </button>
                    <transition name="layout-submenu-container">
                        <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'settings'">
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-palette"></i>
                                    <span>Change Theme</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-star"></i>
                                    <span>Favorites</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-lock"></i>
                                    <span>Lock Screen</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-image"></i>
                                    <span>Wallpaper</span>
                                </a>
                            </li>
                        </ul>
                    </transition>
                </li>

                <li class="hidden" :class="[{ 'active-top-menu': activeTopbarItem === 'messages' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'messages' })">
                    <button class="p-link">
                        <i class="topbar-icon pi pi-fw pi-envelope" v-badge.warning="5"></i>
                        <span class="topbar-item-name">Messages</span>
                    </button>

                    <transition name="layout-submenu-container">
                        <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'messages'">
                            <li role="menuitem">
                                <a class="topbar-message" href="#">
                                    <img alt="Avatar 1" src="layout/images/avatar1.png" width="35" />
                                    <span>Give me a call</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a class="topbar-message" href="#">
                                    <img alt="Avatar 2" src="layout/images/avatar2.png" width="35" />
                                    <span>Sales reports attached</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a class="topbar-message" href="#">
                                    <img alt="Avatar 3" src="layout/images/avatar3.png" width="35" />
                                    <span>About your invoice</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a class="topbar-message" href="#">
                                    <img alt="Avatar 4" src="layout/images/avatar2.png" width="35" />
                                    <span>Meeting today at 10pm</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a class="topbar-message" href="#">
                                    <img alt="Avatar 5" src="layout/images/avatar4.png" width="35" />
                                    <span>Out of office</span>
                                </a>
                            </li>
                        </ul>
                    </transition>
                </li>

                <li class="hidden" :class="[{ 'active-top-menu': activeTopbarItem === 'notifications' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'notifications' })">
                    <button class="p-link">
                        <i class="topbar-icon pi pi-fw pi-bell" v-badge.warning="4"></i>
                        <span class="topbar-item-name">Notifications</span>
                    </button>

                    <transition name="layout-submenu-container">
                        <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'notifications'">
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-sliders-h"></i>
                                    <span>Pending tasks</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-calendar"></i>
                                    <span>Meeting today at 3pm</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-download"></i>
                                    <span>Download documents</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="pi pi-fw pi-bookmark"></i>
                                    <span>Book flight</span>
                                </a>
                            </li>
                        </ul>
                    </transition>
                </li>

                <li class="hidden" :class="['search-item', { 'active-top-menu': activeTopbarItem === 'search' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'search' })">
                    <div class="topbar-search">
                        <input type="text" placeholder="Search" />
                        <i class="pi pi-search"></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { usuarioStore } from '@/store/usuarioStore';
import { logoStore } from '@/store/logosStore';
export default {
    props: {
        topbarMenuActive: Boolean,
        profileMode: String,
        horizontal: Boolean,
        activeTopbarItem: String,
    },
    data() {
        return {
            usuario: usuarioStore(),
            logos: logoStore(),
        };
    },
    methods: {
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
    },
    computed: {
        topbarItemsClass() {
            return [
                'topbar-items fadeInDown',
                {
                    'topbar-items-visible': this.topbarMenuActive,
                },
            ];
        },
        getLogo(){
            let logo = this.logos.getLogo(this.usuario.sedeId,this.usuario.usuarioCargos);
            return logo;
        }
    },
};
</script>
