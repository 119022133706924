export default [
    {
        path: '/academico/prospectos-marketing',
        name: 'academico-prospectos-marketing',
        component: () => import('@/modules/academico/prospectos/layouts/ProspectoMarketingLayout.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/academico/prospectos-proyectistas',
        name: 'academico-prospectos-proyectistas',
        component: () => import('@/modules/academico/prospectos/layouts/ProspectoProyectistaLayout.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
    {
        path: '/academico/prospectos-nacionales',
        name: 'academico-prospectos-nacionales',
        component: () => import('@/modules/academico/prospectos/layouts/ProspectoNacionalLayout.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
];
