<template>
	<div class="login-body">
		<div class="login-image"></div>
		<div class="card login-panel p-fluid">
			<div class="login-panel-content">
				<div class="grid">
					<div class="col-3" style="text-align:left">
						<img src="layout/images/login/icon-login.svg" alt="avalon-vue"/>
					</div>
					<div class="col-9" style="text-align:left">
						<h2 class="welcome-text">Bienvenido</h2>
						<span class="guest-sign-in">Ingrese sus datos de acceso</span>
					</div>
					<div class="col-12" style="text-align:left">
						<label class="login-label">Nombre de usuario</label>
						<div class="login-input">
							<InputText v-model.trim="username" placeholder="Usuario"/>
						</div>
					</div>
					<div class="col-12" style="text-align:left">
						<label class="login-label">clave</label>
						<div class="login-input">
							<InputText v-model.trim="password" type="password" placeholder="Password"/>
						</div>
					</div>
					<div class="col-12 md:col-6 button-pane">
                        <p> {{ msg }} </p>
                        <Button label="Acceder" @click="login()"/>
						<!-- <router-link to="/">
						</router-link> -->
					</div>
					<!-- <div class="col-12 md:col-6 link-pane">
						<button class="p-link">Forget Password?</button>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loginservice from './loginService.js'
	export default {
		data() {
			return {
				username: '',
				password: '',
                msg:''
			}
		},
        methods:{
            async login(){
                this.msg = '';
                if(!this.username) this.msg = "ingrese su nombre de usuario";
                if(!this.password) this.msg = "ingrese su clave";
                if(this.msg) return; 
				let res = await Loginservice.loginGql(this.username, this.password);
				if (res.accessToken){
					console.log(res.accessToken);
					this.$router.replace('/?token='+res.accessToken)
				}else{
					this.msg = "usuario o clave incorrectos"
				}
            }
        }
	}
</script>

<style scoped>

</style>
