export default [
    {
        path: '/academico/plantillas',
        name: 'academico-plantillas',
        component: () => import('@/modules/academico/plantillas/layouts/PlantillaLayout.vue'),
        meta: {
            slug: 'area-ver', // para los permisos
        },
    },
];
