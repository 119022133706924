export default [
    {
        path: '/academico/proyectos',
        name: 'academico-proyectos',
        component: () => import('@/modules/academico/proyectos/layouts/ProyectoLayout.vue'),
        meta:{
            slug: 'academico-proyectos',
        },
    },
    {
        path: '/academico/proyectos/crear-proyecto/:id',
        name: 'academico-proyectos-crear-proyecto',
        component: () => import('@/modules/academico/proyectos/layouts/CrearProyectoLayout.vue'),
        meta: {
            slug: 'academico-proyectos', // para los permisos
            activeLink: '/academico/proyectos',
        },
    },
    {
        path: '/academico/proyectos/validacion',
        name: 'academico-proyectos-validacion',
        component: () => import('@/modules/academico/proyectos/layouts/ProyectoValidacionLayout.vue'),
        meta: {
            slug: 'academico-proyectos', // para los permisos
        },
    },
    {
        path: '/academico/proyectos/nacional',
        name: 'academico-proyectos-nacional',
        component: () => import('@/modules/academico/proyectos/layouts/ProyectoNacionalLayout.vue'),
        meta: {
            slug: 'academico-proyectos', // para los permisos
        },
    },
    // {
    //     path: '/academico/proyectos/validaciona/:id',
    //     name: 'academico-proyectos-validacion',
    //     component: () => import('@/modules/academico/proyectos/layouts/ProyectoValidacionLayout.vue'),
    //     meta: {
    //         slug: 'academico-proyectos', // para los permisos
    //         activeLink: '/academico/proyectos/validacion',
    //     },
    // },
];
