import { defineStore } from 'pinia'

export const logoStore = defineStore('logo_unidad', {
    state: () => ({ 
      logo_unidad_url: null || localStorage.getItem('logo_unidad_url'), 
      id_unidad_negocio: null || localStorage.getItem('id_unidad_negocio'), 
    }),
    getters: {
        getLogoUnidadUrl: state => ({logo_url: state.logo_unidad_url}),
        getIdUnidadNegocio: state => ({unidad: state.id_unidad_negocio}),
    },
    actions: {
        getLogo(sedeId,sedes) { 
            let sedeActual = sedes.find(s=>s.sedeId==sedeId);
            this.logo_unidad_url = '';
            if(!sedeActual) {
                this.logo_unidad_url = 'no hay sede actual.jpg';
                return;
            }
            let unidadid = sedeActual.unidad_negocio;
            localStorage.setItem('id_unidad_negocio',unidadid);

            if (unidadid==1) this.logo_unidad_url = 'layout/images/logo_esam.png';
            else if (unidadid==2) this.logo_unidad_url = 'layout/images/logo_cca.png';
            else if (unidadid==3) this.logo_unidad_url = 'layout/images/logo_ck.png';
            else if (unidadid==4) this.logo_unidad_url = 'layout/images/logo_cas.png';
            else if (unidadid==6) this.logo_unidad_url = 'layout/images/logo_cybercorp.png';
            else if (unidadid==10) this.logo_unidad_url = 'layout/images/logo_dbs.png';
            else if (unidadid==12) this.logo_unidad_url = 'layout/images/logo_cctp.png';
            else this.logo_unidad_url = 'none.png'; 
            localStorage.setItem('logo_unidad_url', this.logo_unidad_url);
            return this.logo_unidad_url;
        }, 
        getUnidadNegocioId(sedeId,sedes) { 
            let sedeActual = sedes.find(s=>s.sedeId==sedeId);
            if(!sedeActual) {
                return -1;
            }
            let unidadid = sedeActual.unidad_negocio;
            localStorage.setItem('id_unidad_negocio',unidadid);
            return unidadid;
        }, 
    }
  })
  