
import gql from 'graphql-tag';
import client from '../apollo-client';
const getMe = async () => {
    const query = gql`
        query { 
            me { 
                datos_personales { 
                    id, sexo, nombres, pri_apellido, seg_apellido, estado,tipo, 
                    usuario {
                        foto, firma, correo_corp
                    }
                },
                cargos { 
                    idsede, 
                    cargo { 
                        id, nombre
                    },
                    sede {
                        id,nombre,unidad_negocio
                    },desde,hasta 
                } 
            } 
        }
    `;  
    const { data } = await client.query({ query });
    return data.me;
}

export default {
    getMe,
};