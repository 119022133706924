import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_AUTH,
});
const authLink = setContext((_, { headers }) => {
  //const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      //Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
