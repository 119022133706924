import { createRouter, createWebHashHistory } from 'vue-router';
import academicoProspectoRouter from '@/modules/academico/prospectos/router';
import academicoProyectoRouter from '@/modules/academico/proyectos/router';
import academicoPlantillaRouter from '@/modules/academico/configuracion/plantillas/router';
import academicoProfesionalRouter from '@/modules/academico/configuracion/profesionales/router';
import { rutas } from './routerx'
import academicoPlantillasRouter from '@/modules/academico/plantillas/router';

const routes = [
    {
        path: '/',
        component: () => import('./components/DashboardDemo.vue'),
        // redirect: '/loginAccess'
    },

    ...academicoProspectoRouter,
    ...academicoProyectoRouter,
    ...academicoPlantillaRouter,
    ...academicoProfesionalRouter,
    ...academicoPlantillasRouter,

    {
        path: '/pinia',
        name: 'pinia',
        component: () => import('./examples/Pinia.vue'),
    },
    {
        path: '/dashboards/generic',
        name: 'dashboards',
        component: () => import('./components/DashboardDemo.vue'),
    },
    {
        path: '/dashboards/dashboard_banking',
        name: 'dashboard_banking',
        component: () => import('./components/DashboardBanking.vue'),
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue'),
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue'),
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue'),
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue'),
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue'),
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue'),
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue'),
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue'),
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue'),
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlaysDemo.vue'),
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue'),
    },
    {
        path: '/menus',
        component: () => import('./components/MenusDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
            },
            {
                path: '/menus/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
            },
            {
                path: '/menus/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
            },
            {
                path: '/menus/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue'),
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue'),
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartsDemo.vue'),
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue'),
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./utilities/Icons.vue'),
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue'),
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/CalendarDemo.vue'),
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue'),
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('./pages/Invoice.vue'),
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('./pages/Help.vue'),
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
    },  
    {
        path: '/main',
        name: 'main',
        component: () => import('./components/DashboardDemo.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
    },
    {
        path: '/wizard',
        name: 'wizard',
        component: () => import('./pages/Wizard.vue'),
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue'),
    },
    ...rutas
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

export default router;
