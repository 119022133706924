export default [
    {
        path: '/academico/configuracion/plantillas',
        name: 'academico-configuracion-plantillas',
        component: () => import('@/modules/academico/configuracion/plantillas/layouts/PlantillaLayout.vue'),
        meta: {
            slug: 'plantilla-ver', // para los permisos
        },
    },
];
