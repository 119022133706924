<template>
	<div class="login-body">
		<div class="login-image"></div>
		<div class="card login-panel p-fluid">
			<div class="login-panel-content">
				<div class="grid">
					<div class="col-3" style="text-align:left">
						<img src="layout/images/login/icon-login.svg" alt="avalon-vue"/>
					</div>
					<div class="col-9" style="text-align:left">
						<h2 class="welcome-text">Welcome Guest</h2>
						<span class="guest-sign-in">Sign in to Avalon Network</span>
					</div>
					<div class="col-12" style="text-align:left">
						<label class="login-label">Username</label>
						<div class="login-input">
							<InputText placeholder="Username"/>
						</div>
					</div>
					<div class="col-12" style="text-align:left">
						<label class="login-label">Password</label>
						<div class="login-input">
							<InputText type="password" placeholder="Password"/>
						</div>
					</div>
					<div class="col-12 md:col-6 button-pane">
						<router-link to="/">
							<Button label="Login" />
						</router-link>
					</div>
					<div class="col-12 md:col-6 link-pane">
						<button class="p-link">Forget Password?</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				username: '',
				password: ''
			}
		}
	}
</script>

<style scoped>

</style>
