import { defineStore } from 'pinia'
import { UsuarioService } from '../service-graphql/index.js';

export const usuarioStore = defineStore('usuario', {
    state: () => ({
      usuarioCargos: [],
      usuario:{},
      sedeId: null || localStorage.getItem('sedeId'),
      usuarioSedes:{},
    }),
    getters: {
        getUsuario: state => ({usuario: state.usuario, cargos: state.usuarioCargos, sedes: state.usuarioSedes, sedeId: state.sedeId, nombreCompleto: `${state.usuario.nombres} ${state.usuario.pri_apellido} ${state.usuario.seg_apellido}`}),
    },
    actions: {
        async getMe() { 
            try {
                const response = await UsuarioService.getMe();
                // console.log('GET_ME:',response);
                this.usuario = response.datos_personales;
                this.usuarioCargos = response.cargos.map(g => {
                    return {
                        sede: `${g.sede.nombre} - ${g.cargo.nombre}`,
                        sedeId: g.idsede,
                        unidad_negocio: g.sede.unidad_negocio,
                    }
                });
                this.usuarioSedes = response.cargos.map(g => {
                    return {
                        sede: `${g.sede.nombre}`,
                        sedeId: g.idsede,
                        unidad_negocio: g.sede.unidad_negocio,
                    }
                });
                this.usuarioSedes.unshift({
                    sede: "Todos",
                    sedeId: null,
                    unidad_negocio: null,
                });
                this.sedeId = this.sedeId ? parseInt(this.sedeId) : parseInt(response.cargos[0].idsede);
                localStorage.setItem('sedeId', this.sedeId);
            } catch (err) {
                // en caso de error redireccionar al portal
                // window.location.href = 'webdev.esam.edu.bo';
                console.log(err)
            }
        },
        cambiarSede(){
            // console.log('cambiosede::', this.sedeId,this.usuario);
            localStorage.setItem('sedeId', this.sedeId);
        }
    }
  })
  