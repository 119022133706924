export const rutas = [
    {
        path: '/desmadre',
        component: () => import('./pages/desmadre/index.vue')
    },
    {
        path: '/desmadre2',
        component: () => import('./pages/desmadre2/index.vue')
    },
    {
        path: '/loginAccess',
        name: 'loginAccess',
        component: () => import('./modules/login/LoginAccess.vue'),
    },
    {
        path: '/academico/oferta',
        name: 'oferta',
        component: () => import('./modules/academico/oferta/index.vue'),
    },
    {
        path: '/academico/proyectos/editar-proyecto/:id',
        name: 'academico-proyectos-editar-proyecto',
        component: () => import('@/modules/academico/proyectos/layouts/EditarProyectoLayout.vue'),
        meta: {
            slug: 'academico-proyectos', // para los permisos
            activeLink: '/academico/proyectos',
        },
    },
]