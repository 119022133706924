export default [
    {
        path: '/academico/configuracion/profesionales',
        name: 'academico-configuracion-profesionales',
        component: () => import('@/modules/academico/configuracion/profesionales/layouts/ProfesionalLayout.vue'),
        meta: {
            slug: 'profesional-ver', // para los permisos
        },
    },
];
