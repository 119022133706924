
import gql from 'graphql-tag';
import client from '../../apollo-client-auth';
const loginGql = async (user, pass) => {
    const query = gql`
        mutation login($user:String!, $pass:String!){
            login(login: $user, clave: $pass){
                accessToken
            }
        }
        `;
    const { data } = await client.mutate({ mutation:query,variables:{user:user,pass:pass} });
    return data.login;
}

export default {
    loginGql,
};