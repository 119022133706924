import { usuarioStore } from '@/store/usuarioStore';
// import { logoStore } from '@/store/logosStore';
export default {
    computed: {
        usuarioMixin() {
            return usuarioStore().getUsuario;
        },
        /* logosMixin(){
            return logoStore().getLogoUnidadUrl;
        } */
    },
};
